<template>
  <form-validation @submit="submitForm">
    <b-row>
      <b-col sm="12" md="8">
        <my-card :isloading="cardloading" :title="code">
          <template slot="body">
            <b-row>
              <b-col sm="12">
                <form-select :filter-id="idBranchArray" :disabled="formStatus==3" ref="branchSelect" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" @value-changed="branch_name = $event.label" :rules="{required: true}" v-model="id_branch" label="Cabang"></form-select>
              </b-col>
              <b-col sm="12" md="8">
                <form-date :disabled="!cP(88)||formStatus==3" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12">
                <label class="ml-3 mb-1" for="termin">Termin</label>
                <b-row>
                  <b-col sm="1">
                    <b-form-radio class="float-right" id="termin" v-model="term" :value="1"></b-form-radio>
                  </b-col>
                  <b-col>
                    <form-select :disabled="term!=1 || formStatus==3" size="sm" ref="bankSelect" url="v1/bank_select2" @value-changed="name_bank = $event.label" :rules="{required: term==1}" v-model="id_bank" label="" placeholder="Pilih Bank"></form-select>
                  </b-col>
                  <b-col>
                    <b-form-radio-group v-model="term">
                      <b-form-radio :value="2" class="font-weight-bolder">Kas Kecil</b-form-radio>
                      <b-form-radio :value="3" class="font-weight-bolder">Hutang</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <form-select :disabled="formStatus!=0" ref="supplierSelect" url="v1/supplier_select2" @value-changed="supplier_name = $event.label" :rules="{required: true}" v-model="id_supplier" label="Pemasok"></form-select>
              </b-col>
              <b-col sm="12">
                <form-textarea :disabled="formStatus==2||formStatus==3" :rules="{required: false}" v-model="note" label="Catatan"></form-textarea>
              </b-col>
              <b-col sm="12" v-if="formStatus==2">
                <form-textarea :rules="{required: false}" v-model="note_main_branch" label="Catatan Pusat"></form-textarea>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
      <b-col v-if="$route.params.id" sm="12" md="4">
        <my-card title="Informasi User">
          <template slot="body">
            <table class="w-100">
              <tbody>
                <tr>
                  <td class="font-weight-bold">Di Input Oleh :</td>
                  <td class="text-right">{{inputInfo.user_create}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Di Update Oleh :</td>
                  <td class="text-right">{{inputInfo.user_update}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Di Approve Oleh :</td>
                  <td class="text-right">{{inputInfo.user_approval}}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </my-card>
      </b-col>
      <b-col sm="12">
        <my-card :isloading="cardloading" title="Detail Item">
          <template slot="body">
            <div>
              <b-row v-for="(tr,i) in details" :key="i">
                <b-col sm="12" md="2">
                  <form-select :clearable="false" :disabled="!isPusat||formStatus==3" size="sm" :ref="`itemSelect-${i}`" url="v1/item_select2" @value-changed="rowItemChange($event,i)" :rules="{required: true}" v-model="tr.id_item" :label="`Item ke ${i+1}`"></form-select>
                </b-col>
                <b-col sm="4" md="1">
                  <form-input-number :rules="{ required: true }" :disabled="!isPusat||formStatus==3" @input="calculateRow(i)" size="sm" v-model="tr.qty" label="Qty"></form-input-number>
                </b-col>
                <b-col sm="6" md="2">
                  <form-select :clearable="false" size="sm" :disabled="!isPusat||formStatus==3" :ref="`unitSelect-${i}`" url="v1/multi_unit_select2" :queryparams="{id_item: tr.id_item}" @value-changed="rowUnitChange($event,i)" :rules="{required: true}" v-model="tr.id_unit" label="Stn"></form-select>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number :disabled="formStatus==3" :rules="{ required: true }" @keyup="calculateRow(i)" size="sm" v-model="tr.price" label="Harga"></form-input-number>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number :disabled="formStatus==3" :rules="{ required: true }" @keyup="calculateRow(i)" size="sm" v-model="tr.disc_percent_d" label="Diskon (%)"></form-input-number>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number :disabled="formStatus==3" :rules="{ required: true }" @keyup="calculateRow(i,false)" size="sm" v-model="tr.disc_amount_d" label="Diskon (Rp)"></form-input-number>
                </b-col>
                <b-col sm="6" md="2">
                  <form-input-number :rules="{ required: true }" disabled size="sm" v-model="tr.total_price" label="Total Harga"></form-input-number>
                </b-col>
                <b-col sm="12" md="1">
                  <form-input :disabled="formStatus==3" size="sm" v-model="tr.note" label="Catatan"></form-input>
                </b-col>
                <b-col>
                  <b-button v-if="i>0 && isPusat" class="mt-2" block variant="danger" @click="deleteRow(i)" size="sm">
                    <feather-icon icon="TrashIcon"></feather-icon> Hapus
                  </b-button>
                </b-col>
                <b-col sm="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col sm="12" md="2" class="mt-2" order-sm="1" order-md="0">
                <b-button v-if="isPusat&&formStatus!=3" block variant="primary" @click="addRow">
                  <feather-icon icon="PlusIcon"></feather-icon> Tambah Detail
                </b-button>
              </b-col>
              <b-col sm="12" offset-md="7" md="3">
                <b-row>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalSub" label="Sub Total" :rules="{required: true}"></form-input-number>
                  </b-col>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalDisc" label="Total Diskon" :rules="{required: true}"></form-input-number>
                  </b-col>
                  <b-col sm="12">
                    <form-input-number disabled :value="totalGrand" label="Grand Total" :rules="{required: true}"></form-input-number>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="formStatus!=3" sm="12" class="mt-4">
                <button-save v-if="formStatus!=2" :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'purchases'}"></button-back>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
    </b-row>
  </form-validation>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import {BButton,BFormRadio,BFormRadioGroup} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components:{
    FormDate,
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
    BButton,
    BFormRadio,
    BFormRadioGroup
  },
  data(){
    return {
      isloading: false,
      cardloading: false,
      status: "",
      id: this.$route.params.id||null,
      code:'',
      id_branch: null,
      branch_name: "",
      date: this.$moment().format(),
      term: 1,
      name_term: "",
      id_bank: null,
      name_bank: "",
      id_supplier: null,
      supplier_name: "",
      sub_total: 0,
      disc_percent: 0,
      disc_amount: 0,
      grand_total: 0,
      note: "",
      details : [],
      inputInfo: {},
      id_request: null
    }
  },
  computed:{
    totalQty(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total+=parseFloat(e.qty)
      }
      return total
    },
    totalSub(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total+= _.multiply(e.qty,e.price)
      }
      return total
    },
    totalDisc(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total+= _.multiply(e.disc_amount_d)
      }
      return total
    },
    totalDiscP(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total+= _.multiply(e.disc_percent_d)
      }
      return total
    },
    totalGrand(){
      return _.subtract(this.totalSub,this.totalDisc)
    },
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      if(meta.isApprove) return 2
      if(meta.isShow) return 3
      else return 0
    },
  },
  methods:{
    ...mapActions({
      dispatchStore: 'purchases/store',
      dispatchShow: 'purchases/show',
      dispatchUpdate: 'purchases/update',
      dispatchApprove: 'purchases/approve',
    }),
    async getData(){
      this.cardloading = true
      if(this.id){
        const data = await this.dispatchShow(this.id)
        const {user_create,user_update,user_approval} = data
        this.inputInfo = {user_create,user_update,user_approval}
        this.id_request = data.id_request
        this.status = data.status
        this.code = data.code
        this.id_branch = data.id_branch
        this.branch_name = data.branch_name
        this.name_term = data.name_term
        this.date = data.date
        this.term = data.term ? data.term : 1
        this.note = data.note
        this.id_supplier = data.id_supplier
        this.id_bank = data.id_bank
        this.bank_name = data.bank_name
        this.supplier_name = data.supplier_name
        this.id_branch_sender = data.id_branch_sender
        this.branch_sender_name = data.branch_sender_name
        this.details = data.purchase_details
        if(this.formStatus==1) this.details = data.purchase_details
        if(data.id_branch) this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
        if(data.id_supplier) this.$refs.supplierSelect.initOption([{value:data.id_supplier,label:data.supplier_name}])
        if(data.term==1) this.$refs.bankSelect.initOption([{value:data.id_bank,label:data.name_bank}])

        for (let i = 0; i < this.details.length; i++) {
          const e = this.details[i];
          this.$nextTick(() => {
            this.$refs[`itemSelect-${i}`][0].initOption([{value:e.id_item,label:e.name_item}])
            this.$refs[`unitSelect-${i}`][0].initOption([{value:e.id_unit,label:e.name_unit}])
          })
        }
      } else {
        const defaults = this.$store.state.auth
        this.$refs.branchSelect.initOption(defaults.defaultbranches)
        this.id_branch = defaults.profile.id_branch
        this.branch_name = defaults.profile.branch_name
        this.addRow()
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,id_branch,date,name_term,branch_name,term,id_bank,name_bank,id_supplier,supplier_name,note,details} = this
      let params = {id,code,id_branch,date,name_term,branch_name,term,id_bank,name_bank,id_supplier,supplier_name,note,details}
      params.sub_total = this.totalSub
      params.disc_percent = this.totalDiscP
      params.disc_amount = this.totalDisc
      params.grand_total = this.totalGrand
      if(!id){
        if(term==1) params.name_term = name_bank
        else if(term==2) params.name_term = "Kas Kecil"
        else params.name_term = "Hutang"
      }
      try {
        if(this.formStatus==0) await this.dispatchStore(params)
        else await this.dispatchUpdate(params)
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'purchases'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    addRow(){
      const defaultrow = {
          id_item: null,
          name_item: "",
          qty: 1,
          id_unit: null,
          name_unit: "",
          price: 0,
          disc_percent_d: 0,
          disc_amount_d: 0,
          total_price: 0,
          note: ""
      }
      let data = JSON.parse( JSON.stringify(defaultrow) )
      if(this.formStatus==2){
        data = Object.assign({},defaultrow,{
          qty_approve: 1,
          id_unit_approve: null,
          name_unit_approve: ""
        })
      }
      this.details.push(data)
    },
    deleteRow(row){
      this.details.splice(row,1)
    },
    onClickApprove(){
      this.approvalButton = "Disetujui Pusat"
      this.submitForm()
    },
    onClickReject(){
      this.approvalButton = "Ditolak Pusat"
      this.submitForm()
    },
    async rowItemChange(e,row){
      let tr = this.details[row]
      // const item = await this.$store.dispatch('items/show', tr.id_item)
      // const units = await this.$http.get('v1/multi_unit_select2',{params:{id_item:tr.id_item,name:""}})
      // this.$refs[`unitSelect-${row}`][0].initOption(units.data)
      tr.name_item = e.label
      tr.id_unit = null
      tr.price = 0
      tr.total_price = 0
      // const find = units.data.find(d => d.value==tr.id_item)
      // if(find) {
      //   tr.price = find.purchase_price
      //   tr.total_price = _.add(find.purchase_price,tr.qty)
      // }
    },
    rowUnitChange(e,row){
      let tr = this.details[row]
      tr.name_unit = e.label
      tr.price = e.purchase_price
      this.calculateRow(row)
    },
    calculateRow(row,isDiscPercent=true){
      let tr = this.details[row]
      if (isDiscPercent) {
        const subtotal = _.multiply(tr.price, tr.qty)
        if(subtotal>0) tr.disc_amount_d = _.round((subtotal * parseFloat(tr.disc_percent_d)/100),2)
        tr.total_price = subtotal - tr.disc_amount_d
      } else {
        const subtotal = _.multiply(tr.price, tr.qty)
        if(subtotal>0) tr.disc_percent_d = _.round((tr.disc_amount_d/subtotal * 100), 2)
        tr.total_price = subtotal - tr.disc_amount_d
      }
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>